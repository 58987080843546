import {Observable, Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {AfterViewInit, Component, Input, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {CallOptions, ConferencingState} from 'src/app/state/conferencing.reducer';
import {Store} from '@ngrx/store';
import * as UserActions from 'src/app/state/user.actions';
import * as ConferencingActions from 'src/app/state/conferencing.actions';
import {selectCallEnded} from 'src/app/state/conferencing.selectors';
import {MatDialogRef} from '@angular/material/dialog';
import {IdcapService, MediaControl} from '../../../core/services/idcap.service';

@Component({
  selector: 'cm-accept-call-dialog',
  templateUrl: './accept-call-dialog.component.html',
  styleUrls: ['./accept-call-dialog.component.scss'],
})
export class AcceptCallDialogComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  choices: Array<any> = [];
  dialogRef: MatDialogRef<AcceptCallDialogComponent>;
  callEnded$: Observable<boolean>;
  callEndedSubscription: Subscription;
  media: any;

  // ringingSoundUrl = 'http://46.101.32.47:9003/ringing.mp3';
  // declinedSoundUrl = 'http://46.101.32.47:9003/declined.mp3';
  ringingSoundUrl = `${document.location.origin}/assets/sounds/ringing.mp3`;
  declinedSoundUrl = `${document.location.origin}/assets/sounds/declined.mp3`;

  @Input() callOptions: CallOptions;

  constructor(
    private router: Router,
    private renderer: Renderer2,
    private store$: Store<ConferencingState>,
    private idcapService: IdcapService
  ) {}

  ngOnInit(): void {
    // add choices
    this.choices = [
      { id: 1, label: '', icon: 'cm:tick-circle' },
      { id: 2, label: '', icon: 'cm:cross-circle' },
    ];

    this.callEnded$ = this.store$.select(selectCallEnded);
    this.callEndedSubscription = this.callEnded$.subscribe((ended: boolean) => {
      if (ended) {
        this.callEnded();
      }
    });
  }

  ngAfterViewInit(): void {
    this.initializeMedia();
  }

  async initializeMedia(): Promise<void> {
    await this.mediaSetUp();
    await this.playRingingSound();
  }

  ngOnDestroy(): void {
    this.callEndedSubscription.unsubscribe();
  }

  async onChoiceSelected(choice): Promise<void> {
    if (choice.id === 1) {
      await this.accept();
    } else {
      await this.decline();
    }
  }

  async accept(): Promise<void> {
    console.info('accept call clicked');
    this.store$.dispatch(ConferencingActions.acceptCall());
    await this.stopRingingSound();
    await this.router.navigate([
      'conferencing/call-viewer',
      { callType: 'incoming' },
    ]);
  }

  async decline($event?): Promise<void> {
    console.info('decline call clicked');
    this.store$.dispatch(
      ConferencingActions.declineCall({ reason: 'user-decline' })
    );
    this.store$.dispatch(ConferencingActions.resetState());
    await this.stopRingingSound();
    await this.mediaShutDown();
  }

  async callEnded(): Promise<void> {
    this.store$.dispatch(ConferencingActions.resetState());
    await this.stopRingingSound();
    await this.mediaShutDown();
    this.close();
  }

  close($event?): void {
    this.store$.dispatch(UserActions.navigateToHome());
  }

  async mediaSetUp(): Promise<void> {
    if (this.idcapService.isAvailable()) {
      return this.idcapService.mediaStartUp();
    }
  }

  async mediaShutDown(): Promise<void> {
    if (this.idcapService.isAvailable()) {
      return this.idcapService.mediaShutDown();
    }
  }

  async playRingingSound(): Promise<void> {
    if (this.idcapService.isAvailable()) {
      return this.idcapService.mediaCreate(this.ringingSoundUrl, 'audio/mp3').then(() => {
        return this.idcapService.mediaControl(MediaControl.PLAY, 0).then(() => {
          console.log('ringing sound started');
        }, console.error);
      });
    }
  }

  async playDeclinedSound(): Promise<void> {
    if (this.idcapService.isAvailable()) {
      return this.idcapService.mediaCreate(this.declinedSoundUrl, 'audio/mp3').then(() => {
        return this.idcapService.mediaControl(MediaControl.PLAY, 1).then(() => {
          console.log('declined sound started');
          return this.idcapService.mediaControl(MediaControl.STOP).then(() => {
            console.log('declined sound stopped');
            return this.idcapService.mediaDestroy().then(() => {
              console.log('declined sound destroyed');
            }, console.error);
          }, console.error);
        }, console.error);
      });
    }
  }

  async stopRingingSound(): Promise<void> {
    if (this.idcapService.isAvailable()) {
      return this.idcapService.mediaControl(MediaControl.STOP).then(() => {
        return this.idcapService.mediaDestroy().then(() => {
          console.log('ringing sound stopped');
        }, console.error);
      }, console.error);
    }
  }
}
