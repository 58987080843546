import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import { CallOptions } from '../../../state/conferencing.reducer';
import * as ConferencingActions from '../../../state/conferencing.actions';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {IdcapService} from '../../../core/services/idcap.service';

@Component({
  selector: 'cm-accept-call-with-delay-dialog',
  templateUrl: './accept-call-with-delay-dialog.component.html',
  styleUrls: ['./accept-call-with-delay-dialog.component.scss'],
  animations: [
    trigger('animateTrigger', [
      state(
        'true',
        style({
          transform: 'scale(1)',
          transformOrigin: '50% 50%',
        })
      ),
      state(
        'false',
        style({
          transform: 'scale(1.3)',
          transformOrigin: '50% 50%',
        })
      ),
      transition('* <=> *', animate('300ms ease-in')),
    ]),
  ],
})
export class AcceptCallWithDelayDialogComponent implements OnInit, AfterViewInit {
  @Input() callOptions: CallOptions;
  countDown: number;
  animate = false;
  constructor(private store$: Store, private router: Router, private idcapService: IdcapService) {
    console.log('callOptions', this.callOptions);
  }

  ngOnInit(): void {
    this.countDown = this.callOptions.acceptAfter;
    const interval = setInterval(() => {
      this.animate = !this.animate;
      this.countDown--;
      console.log(this.countDown);
      if (this.countDown === 0) {
        clearInterval(interval);
        this.store$.dispatch(ConferencingActions.acceptCall());
        this.redirectToCallViewer();
      }
    }, 1000);
  }

  ngAfterViewInit(): void {
    this.initializeMedia();
  }

  async initializeMedia(): Promise<void> {
    await this.mediaSetUp();
  }

  async mediaSetUp(): Promise<void> {
    if (this.idcapService.isAvailable()) {
      return this.idcapService.mediaStartUp();
    }
  }

  async redirectToCallViewer(): Promise<void> {
    await this.router.navigate([
      'conferencing/call-viewer',
      { callType: 'incoming' },
    ]);
  }
}
