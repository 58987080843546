import {Component, OnInit} from '@angular/core';
import { Store } from '@ngrx/store';
import {
  CallOptions,
  ConferencingState,
} from 'src/app/state/conferencing.reducer';
import { Observable } from 'rxjs';
import { selectInboundCallOptions } from 'src/app/state/conferencing.selectors';

@Component({
  selector: 'cm-accept-call',
  templateUrl: './accept-call.component.html',
  styleUrls: ['./accept-call.component.scss'],
})
export class AcceptCallComponent implements OnInit {
  inboundCallOptions$: Observable<CallOptions>;

  constructor(private store$: Store<ConferencingState>) {}

  ngOnInit(): void {
    this.inboundCallOptions$ = this.store$.select(selectInboundCallOptions);
  }
}
